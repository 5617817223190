<template>
  <v-container fluid>
    <!-- ADHOC PROJECT REPORTS -->
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                AdHoc Project Reports
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row>
                    <v-col sm="12" md="2">
                      <BaseDatePickerWithText
                        label="Start Date"
                        clearable
                        v-model="startDate"
                      ></BaseDatePickerWithText>
                    </v-col>
                    <v-col sm="12" md="2">
                      <BaseDatePickerWithText
                        label="End Date"
                        clearable
                        v-model="endDate"
                      ></BaseDatePickerWithText>
                    </v-col>

                    <v-col sm="12" md="2">
                      <v-text-field
                        v-model="searchText"
                        prepend-inner-icon="mdi-text-box-search"
                        label="Search"
                        hint="Entity, SubEntity, Project, Strategy, Details"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="3" class="mt-4">
                      <v-select
                        v-model="selectedCategories"
                        :items="availableCategories"
                        item-text="text"
                        item-value="value"
                        label="Select Categories"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:selection="{item, index}">
                          <v-chip
                            small
                            v-if="index < 5"
                            close
                            @click:close="selectedCategories.splice(index, 1)"
                            >{{ item.text }}</v-chip
                          >
                          <span v-if="index === 5" class="grey--text caption"
                            >(+{{ selectedCategories.length - 5 }} others)</span
                          >
                        </template>
                      </v-select>
                    </v-col>
                    <v-col sm="12" md="3" class="mt-8">
                      <v-autocomplete
                        v-model="selectedFields"
                        :items="availableHeaders"
                        item-text="text"
                        item-value="value"
                        label="Select Columns"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:selection="{item, index}">
                          <v-chip
                            small
                            v-if="index < 5"
                            close
                            @click:close="selectedFields.splice(index, 1)"
                            >{{ item.text }}</v-chip
                          >
                          <span v-if="index === 5" class="grey--text caption"
                            >(+{{ selectedFields.length - 5 }} others)</span
                          >
                        </template>
                        <template v-slot:prepend>
                          <reorderSelectedFields
                            v-model="selectedFields"
                          ></reorderSelectedFields>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col sm="12" md="2">
                      <h4
                        @click="toggleFilters"
                        class="pointer primary--text"
                        >{{ filterText }}</h4
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="filters">
                    <v-col v-if="isSuperUser" sm="12" md="3">
                      <v-select
                        v-model="projectDivision"
                        :items="availableProjectDivisions"
                        item-text="DivisionName"
                        item-value="ID"
                        label="Project Division"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="entityStatus"
                        :items="statusList"
                        label="Entity Status"
                        chips
                        deletable-chips
                        small-chips
                        multiple
                        clearable
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="subEntityStatus"
                        :items="statusList"
                        label="SubEntity Status"
                        chips
                        deletable-chips
                        small-chips
                        multiple
                        clearable
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="projectStatus"
                        :items="statusList"
                        label="Project Status"
                        chips
                        deletable-chips
                        small-chips
                        multiple
                        clearable
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="projectDetails"
                        :items="availableProjectDetails"
                        item-text="Description"
                        item-value="ID"
                        label="Project Detail"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="projectDetailsPrefix"
                        :items="availableProjectDetailsPrefix"
                        label="Project Detail Prefix"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="departmentStrategy"
                        :items="availableDepartmentStrategies"
                        item-text="Description"
                        item-value="Code"
                        label="Department Strategy"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-select>
                    </v-col>
                    <v-col
                      v-if="
                        divisionDisplayList.includes(5) ||
                        divisionDisplayList.includes(6)
                      "
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="licenseTypes"
                        :items="availableLicenseTypes"
                        item-text="Description"
                        item-value="ID"
                        label="License Types"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="divisionDisplayList.includes(4)"
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="entityTypes"
                        :items="availableEntityTypes"
                        item-text="Description"
                        item-value="ID"
                        label="Entity Type"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="project"
                        :items="projectList"
                        :search-input.sync="projectSearchText"
                        :loading="projectSearchIsLoading"
                        placeholder="Start typing to Search"
                        item-text="details"
                        item-value="ID"
                        label="Project"
                        cache-items
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:selection="{item, index}">
                          <v-chip
                            small
                            v-if="index < 5"
                            close
                            @click:close="project.splice(index, 1)"
                            >{{ item.ProjectNumber }}</v-chip
                          >
                          <span v-if="index === 5" class="grey--text caption"
                            >(+{{ project.length - 5 }} others)</span
                          >
                        </template>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              >No results for search query</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="entity"
                        :items="entityList"
                        :search-input.sync="entitySearchText"
                        :loading="entitySearchIsLoading"
                        placeholder="Start typing to Search"
                        item-text="FullName"
                        item-value="ID"
                        label="Entity"
                        cache-items
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              >No results for search query</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="leads"
                        :items="userList"
                        :search-input.sync="userSearchText"
                        :loading="userSearchIsLoading"
                        placeholder="Start typing to Search"
                        item-text="fullName"
                        item-value="ID"
                        label="Lead"
                        cache-items
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              >No results for search query</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="users"
                        :items="userList2"
                        :search-input.sync="user2SearchText"
                        :loading="user2SearchIsLoading"
                        placeholder="Start typing to Search"
                        item-text="fullName"
                        item-value="ID"
                        label="Other Users"
                        cache-items
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              >No results for search query</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="
                        selectedCategories.includes(
                          'Dates' || selectedCategories.includes('Findings')
                        ) &&
                        (divisionDisplayList.includes(3) ||
                          divisionDisplayList.includes(4) ||
                          divisionDisplayList.includes(5) ||
                          divisionDisplayList.includes(6))
                      "
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="dateTypes"
                        :items="availableDateTypes"
                        item-text="DateType"
                        item-value="ID"
                        label="Date Types"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="
                        selectedCategories.includes('Findings') &&
                        (divisionDisplayList.includes(5) ||
                          divisionDisplayList.includes(6))
                      "
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="findingTypes"
                        :items="availableFindingTypes"
                        item-text="FindingType"
                        item-value="ID"
                        label="Finding Types"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="
                        selectedCategories.includes('Collections') &&
                        (divisionDisplayList.includes(5) ||
                          divisionDisplayList.includes(6))
                      "
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="collectionTypes"
                        :items="availableFindingTypes"
                        item-text="FindingType"
                        item-value="ID"
                        label="Collection Types"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="
                        selectedCategories.includes('ReportedValues') &&
                        (divisionDisplayList.includes(5) ||
                          divisionDisplayList.includes(6))
                      "
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="reportedValueTypes"
                        :items="availableReportedTypes"
                        item-text="ReportedType"
                        item-value="ID"
                        label="Reported Types"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="
                        selectedCategories.includes('Reviews') &&
                        divisionDisplayList.includes(3)
                      "
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="reviewTypes"
                        :items="availableReviewTypes"
                        item-text="Description"
                        item-value="ID"
                        label="Review Types"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="
                        selectedCategories.includes('BudgetHours') &&
                        divisionDisplayList.includes(4)
                      "
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="activityCode"
                        :items="availableActivityCodes"
                        item-text="display"
                        item-value="ID"
                        label="Activity Code"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <pivotOptions
                    :pivot="pivot"
                    :filteredHeaders="filteredHeaders"
                  ></pivotOptions>
                </v-card-text>
                <template>
                  <v-data-table
                    :headers="filteredHeaders"
                    :items="entries"
                    :server-items-length="total"
                    :loading="loading"
                    :sort-by.sync="pagination.sortBy"
                    :sort-desc.sync="pagination.descending"
                    :page.sync="pagination.page"
                    :items-per-page.sync="pagination.rowsPerPage"
                    :footer-props="{
                      itemsPerPageOptions: [5, 10, 25, 50],
                    }"
                    class="pt-4"
                    dense
                  >
                    <template v-slot:item.FindingsValue="{item}">
                      <span>{{ item.FindingsValue | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.FindingsValue65="{item}">
                      <span>{{ item.FindingsValue65 | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.CollectionsValue="{item}">
                      <span>{{ item.CollectionsValue | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.CollectionsValue65="{item}">
                      <span>{{ item.CollectionsValue65 | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.ReportedValue="{item}">
                      <span>{{ item.ReportedValue | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.ReportedValue65="{item}">
                      <span>{{ item.ReportedValue65 | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.RefundAmount="{item}">
                      <span>{{ item.RefundAmount | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.ExpensePOV="{item}">
                      <span>{{ item.ExpensePOV | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.ExpenseStateCar="{item}">
                      <span>{{ item.ExpenseStateCar | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.ExpenseAirFare="{item}">
                      <span>{{ item.ExpenseAirFare | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.ExpenseHotel="{item}">
                      <span>{{ item.ExpenseHotel | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.ExpenseMIE="{item}">
                      <span>{{ item.ExpenseMIE | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.ExpenseRentalCar="{item}">
                      <span>{{ item.ExpenseRentalCar | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.ExpenseOther="{item}">
                      <span>{{ item.ExpenseOther | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.ExpenseTotal="{item}">
                      <span>{{ item.ExpenseTotal | moneyFormat }}</span>
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <!-- GENERATE ADHOC -->
    <v-row justify="end">
      <v-col sm="auto">
        <v-btn @click="getAdhoc" color="primary">
          <v-icon left>mdi-file-chart</v-icon>Generate AdHoc
        </v-btn>
      </v-col>
    </v-row>
    <!-- SAVED QUERIES -->
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel2" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                Saved Queries
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-form @submit.prevent>
                    <v-row justify="space-between">
                      <v-col sm="12" md="6">
                        <v-select
                          :items="availableSavedQueries"
                          item-text="Title"
                          item-value="ID"
                          label="Saved Queries"
                          v-model="selectedQueryID"
                          @change="selectQuery"
                          clearable
                        >
                          <template slot="item" slot-scope="data">
                            <v-row>
                              <v-col
                                v-if="data.item.ID && data.item.ID > 0"
                                sm="auto"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{on}">
                                    <v-btn color="primary" v-on="on" icon>
                                      <v-icon
                                        class="clickable"
                                        color="primary"
                                        @click="deleteConfirm(data.item)"
                                        >mdi-trash-can</v-icon
                                      >
                                    </v-btn>
                                  </template>
                                  <span>Delete Query</span>
                                </v-tooltip>
                              </v-col>
                              <v-col sm="auto" class="mt-4">{{
                                data.item.Title
                              }}</v-col>
                            </v-row>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col sm="12" md="3" class="mt-4">
                        <v-btn color="primary" @click="dialog = true">
                          <v-icon left>mdi-content-save</v-icon>Save New Query
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-dialog v-model="dialog" width="450">
                      <v-card class="elevation-3">
                        <v-card-title class="primary">
                          <h3 class="hpHeading white--text"
                            >Enter New Queries</h3
                          >
                        </v-card-title>
                        <v-card-text class="pa-4">
                          <v-row>
                            <v-col sm="12">
                              <v-text-field
                                label="Query Name"
                                v-model="savedQueriesTitle"
                              ></v-text-field>
                            </v-col>
                            <v-col sm="12">
                              <v-card-actions>
                                <v-btn color="primary" @click="saveQuery"
                                  >Accept</v-btn
                                >
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="dialog = !dialog"
                                  >Cancel</v-btn
                                >
                              </v-card-actions>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <v-dialog persistent v-model="deleteDialog" scrollable width="350">
                      <v-card class="elevation-3">
                        <v-card-title class="primary">
                          <h3 class="hpHeading white--text">Confirm?</h3>
                        </v-card-title>
                        <v-card-text class="pa-4">
                          <v-row>
                            <v-col sm="12"
                              >Are you sure you want to delete this
                              query?</v-col
                            >
                          </v-row>
                        </v-card-text>
                          <v-card-actions>
                            <v-btn
                              color="primary"
                              @click="deleteDialog = !deleteDialog"
                              >Cancel</v-btn
                            >
                            <v-btn
                              @click="deleteQueries"
                              outlined
                              color="primary"
                              class="white--text"
                              >Delete</v-btn
                            >
                          </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-form>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- SCRIPTS -->
<script>
import pivotOptions from '@components/reports/pivotOptionsObject'
import reorderSelectedFields from '@components/reports/reorderSelectedFields'
import dayjs from 'dayjs'
import download from 'downloadjs'
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import {debounce, range} from 'lodash'
import SavedQueriesProject from '@classes/SavedQueriesProject'
import {ProjectReports} from '@defaults/StaticReports'
import draggable from 'vuedraggable'

export default {
  name: 'ProjectReports',
  page: {
    title: 'Project Reports',
  },
  components: {
    draggable,
    pivotOptions,
    reorderSelectedFields,
  },
  data: () => ({
    valid: true,
    panel: [0, 1],
    panel2: [0, 1],
    savedQueriesTitle: '',
    savedQueries: [],
    deleteQuery: {},
    dialog: false,
    deleteDialog: false,
    searchText: '',
    entries: [],
    startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
    projectStatus: ['Active'],
    entityStatus: ['Active'],
    subEntityStatus: ['Active'],
    projectDivision: [],
    project: [],
    entity: [],
    projectDetails: [],
    projectDetailsPrefix: [],
    departmentStrategy: [],
    leads: [],
    users: [],
    counties: [],
    licenseTypes: [],
    entityTypes: [],
    dateTypes: [],
    findingTypes: [],
    collectionTypes: [],
    reportedValueTypes: [],
    reviewTypes: [],
    activityCode: [],
    queryName: '',
    queryIsModified: false,
    pivot: {
      includePivot: false,
      pivotFirst: true,
      pivotName: '',
      rowFields: [],
      columnFields: [],
      excludeFields: [],
      dataFields: [],
      filterFields: [],
    },
    userSearchText: '',
    user2SearchText: '',
    userSearchCount: 0,
    user2SearchCount: 0,
    userList: [],
    userList2: [],
    entitySearchText: '',
    entitySearchCount: 0,
    entityList: [],
    projectSearchText: '',
    projectSearchCount: 0,
    projectList: [],
    filters: true,
    filterText: '-Hide Filters-',
    modalText: '',
    statusList: ['Active', 'Inactive'],

    total: 0,
    searchCount: 0,
    pagination: {
      page: 1,
      sortBy: 'ProjectNumber',
      descending: false,
      rowsPerPage: 25,
    },
    selectedFields: [],
    selectedQueryID: 0,
    staticReports: ProjectReports,
    dataFieldModal: false,
    selectedDataField: '',
    selectedDataType: '',
    selectedCategories: [],
    headers: [
      {
        text: 'Entity',
        align: 'left',
        sortable: 'true',
        value: 'EntityName',
        visible: true,
      },
      {
        text: 'Entity Short Name',
        align: 'left',
        sortable: 'true',
        value: 'EntityShortName',
        visible: false,
      },
      {
        text: 'Entity Type',
        align: 'left',
        sortable: 'true',
        value: 'EntityType',
        divisions: [4],
        visible: true,
      },
      {
        text: 'Entity Status',
        align: 'left',
        sortable: 'true',
        value: 'EntityStatus',
        visible: false,
      },
      // {
      //   text: 'Entity FEIN',
      //   align: 'left',
      //   sortable: 'true',
      //   value: 'EntityFEIN',
      //   visible: false,
      // },
      {
        text: 'Entity Phone',
        align: 'left',
        sortable: 'true',
        value: 'EntityPhone',
        visible: false,
      },
      {
        text: 'Entity Tax Num',
        align: 'left',
        sortable: 'true',
        value: 'EntityTaxNum',
        visible: false,
      },
      {
        text: 'Entity Tax Num Desc',
        align: 'left',
        sortable: 'true',
        value: 'EntityTaxNumDesc',
        visible: false,
      },
      {
        text: 'Entity Address',
        align: 'left',
        sortable: 'true',
        value: 'EntityAddress',
        visible: false,
      },
      {
        text: 'Entity City',
        align: 'left',
        sortable: 'true',
        value: 'EntityCity',
        visible: false,
      },
      {
        text: 'Entity State',
        align: 'left',
        sortable: 'true',
        value: 'EntityState',
        visible: false,
      },
      {
        text: 'Entity Zip',
        align: 'left',
        sortable: 'true',
        value: 'EntityZip',
        visible: false,
      },
      {
        text: 'Entity Physical Address',
        align: 'left',
        sortable: 'true',
        value: 'EntityPhysicalAddress',
        visible: false,
      },
      {
        text: 'Entity Physical City',
        align: 'left',
        sortable: 'true',
        value: 'EntityPhysicalCity',
        visible: false,
      },
      {
        text: 'Entity Physical State',
        align: 'left',
        sortable: 'true',
        value: 'EntityPhysicalState',
        visible: false,
      },
      {
        text: 'Entity Physical Zip',
        align: 'left',
        sortable: 'true',
        value: 'EntityPhysicalZip',
        visible: false,
      },
      {
        text: 'SubEntity Description',
        align: 'left',
        sortable: 'true',
        value: 'SubEntityDescription',
        visible: true,
      },
      {
        text: 'SubEntity Status',
        align: 'left',
        sortable: 'true',
        value: 'SubEntityStatus',
        visible: false,
      },
      {
        text: 'SubEntity Address',
        align: 'left',
        sortable: 'true',
        value: 'SubEntityAddress',
        visible: false,
      },
      {
        text: 'SubEntity City',
        align: 'left',
        sortable: 'true',
        value: 'SubEntityCity',
        visible: false,
      },
      {
        text: 'SubEntity State',
        align: 'left',
        sortable: 'true',
        value: 'SubEntityState',
        visible: false,
      },
      {
        text: 'SubEntity Zip',
        align: 'left',
        sortable: 'true',
        value: 'SubEntityZip',
        visible: false,
      },
      {
        text: 'SubEntity Physical Address',
        align: 'left',
        sortable: 'true',
        value: 'SubEntityPhysicalAddress',
        visible: false,
      },
      {
        text: 'SubEntity Physical City',
        align: 'left',
        sortable: 'true',
        value: 'SubEntityPhysicalCity',
        visible: false,
      },
      {
        text: 'SubEntity Physical State',
        align: 'left',
        sortable: 'true',
        value: 'SubEntityPhysicalState',
        visible: false,
      },
      {
        text: 'SubEntity Physical Zip',
        align: 'left',
        sortable: 'true',
        value: 'SubEntityPhysicalZip',
        visible: false,
      },
      {
        text: 'Project Number',
        align: 'left',
        sortable: 'true',
        value: 'ProjectNumber',
        visible: true,
      },
      {
        text: 'Project Description',
        align: 'left',
        sortable: 'true',
        value: 'ProjectDescription',
        visible: true,
      },
      {
        text: 'Project Status',
        align: 'left',
        sortable: 'true',
        value: 'ProjectStatus',
        visible: false,
      },
      {
        text: 'Audit Start',
        align: 'left',
        sortable: 'true',
        value: 'AuditStart',
        visible: true,
      },
      {
        text: 'Audit End',
        align: 'left',
        sortable: 'true',
        value: 'AuditEnd',
        visible: true,
      },
      {
        text: 'Project Division',
        align: 'left',
        sortable: 'true',
        value: 'ProjectDivisionName',
        visible: false,
      },
      {
        text: 'SubDivision',
        align: 'left',
        sortable: 'true',
        value: 'SubDivision',
        visible: true,
      },
      {
        text: 'Lead Auditor',
        align: 'left',
        sortable: 'true',
        value: 'LeadAuditor',
        visible: true,
      },
      {
        text: 'Lead Role',
        align: 'left',
        sortable: 'true',
        value: 'LeadRole',
        visible: true,
      },
      {
        text: 'Project Details',
        align: 'left',
        sortable: 'true',
        value: 'ProjectDetails',
        visible: true,
      },
      {
        text: 'Dept. Strategy',
        align: 'left',
        sortable: 'true',
        value: 'DepartmentStrategy',
        visible: true,
      },
      {
        text: 'Department Strategy Description',
        align: 'center',
        sortable: 'true',
        value: 'DepartmentStrategyDescription',
        visible: true,
      },
      {
        text: 'License Type',
        divisions: [5, 6],
        align: 'center',
        sortable: 'true',
        value: 'LicenseType',
        visible: false,
      },
      {
        text: 'License Number',
        divisions: [5, 6],
        align: 'center',
        sortable: 'true',
        value: 'LicenseNumber',
        visible: false,
      },
      {
        text: 'UsersWithRole',
        divisions: [5, 6],
        align: 'center',
        sortable: 'true',
        value: 'UsersWithRole',
        visible: false,
      },
      {
        text: 'Users Without Role',
        divisions: [5, 6],
        align: 'center',
        sortable: 'true',
        value: 'UsersWithoutRole',
        visible: false,
      },
      // {
      //   text: 'Date',
      //   align: 'center',
      //   sortable: 'true',
      //   value: 'Date',
      //   visible: false,
      // },
      {
        text: 'From Date',
        align: 'center',
        sortable: 'true',
        value: 'FromDate',
        category: ['Dates', 'Findings'],
        visible: true,
      },
      {
        text: 'To Date',
        align: 'center',
        sortable: 'true',
        value: 'ToDate',
        category: ['Dates', 'Findings'],
        visible: false,
      },
      {
        text: 'Date Type',
        align: 'center',
        sortable: 'true',
        value: 'DateType',
        category: ['Dates', 'Findings'],
        visible: true,
      },
      {
        text: 'Date Comment',
        align: 'center',
        sortable: 'true',
        value: 'DateComment',
        category: ['Dates', 'Findings'],
        visible: false,
      },
      {
        text: 'Sent To',
        align: 'center',
        sortable: 'true',
        value: 'SentTo',
        category: ['Dates', 'Findings'],
        visible: false,
      },
      {
        text: 'Findings Sent Date',
        align: 'center',
        sortable: 'true',
        value: 'FindingsSentDate',
        category: ['Findings'],
        visible: true,
      },
      {
        text: 'Findings Comment',
        align: 'center',
        sortable: 'true',
        value: 'FindingsComment',
        category: ['Findings'],
        visible: false,
      },
      {
        text: 'Findings Type',
        align: 'center',
        sortable: 'true',
        value: 'FindingsType',
        category: ['Findings'],
        visible: true,
      },
      {
        text: 'Findings Value',
        align: 'center',
        sortable: 'true',
        value: 'FindingsValue',
        category: ['Findings'],
        visible: true,
      },
      {
        text: 'Findings Value 6.5%',
        align: 'center',
        sortable: 'true',
        value: 'FindingsValue65',
        category: ['Findings'],
        visible: false,
      },
      {
        text: 'Findings County',
        align: 'center',
        sortable: 'true',
        value: 'FindingsCounty',
        divisions: [5],
        category: ['Findings'],
        visible: false,
      },
      {
        text: 'Collections Received Date',
        align: 'center',
        sortable: 'true',
        value: 'CollectionsReceivedDate',
        category: ['Collections'],
        visible: true,
      },
      {
        text: 'Collections Entered Date',
        align: 'center',
        sortable: 'true',
        value: 'CollectionsEnteredDate',
        category: ['Collections'],
        visible: false,
      },
      {
        text: 'Collections Comment',
        align: 'center',
        sortable: 'true',
        value: 'CollectionsComment',
        category: ['Collections'],
        visible: false,
      },
      {
        text: 'Collections Findings Type',
        align: 'center',
        sortable: 'true',
        value: 'CollectionsFindingsType',
        category: ['Collections'],
        visible: true,
      },
      {
        text: 'Collections Value',
        align: 'center',
        sortable: 'true',
        value: 'CollectionsValue',
        category: ['Collections'],
        visible: true,
      },
      {
        text: 'Collections Value 6.5%',
        align: 'center',
        sortable: 'true',
        value: 'CollectionsValue65',
        category: ['Collections'],
        visible: false,
      },
      {
        text: 'Novc Number',
        align: 'center',
        sortable: 'true',
        value: 'NovcNumber',
        divisions: [5],
        category: ['Collections'],
        visible: false,
      },
      {
        text: 'Collections Year',
        align: 'center',
        sortable: 'true',
        value: 'CollectionsYear',
        divisions: [5],
        category: ['Collections'],
        visible: false,
      },
      {
        text: 'Collections County',
        align: 'center',
        sortable: 'true',
        divisions: [5],
        value: 'CollectionsCounty',
        category: ['Collections'],
        visible: false,
      },
      {
        text: 'Reported Value',
        align: 'center',
        sortable: 'true',
        value: 'ReportedValue',
        category: ['ReportedValues'],
        visible: true,
      },
      {
        text: 'Reported Value 6.5%',
        align: 'center',
        sortable: 'true',
        value: 'ReportedValue65',
        category: ['ReportedValues'],
        visible: false,
      },
      {
        text: 'Reported Value Type',
        align: 'center',
        sortable: 'true',
        value: 'ReportedValueType',
        category: ['ReportedValues'],
        visible: true,
      },
      {
        text: 'Refund Quantity',
        align: 'center',
        sortable: 'true',
        value: 'RefundQuantity',
        category: ['Refunds'],
        visible: true,
      },
      {
        text: 'Refund Amount',
        align: 'center',
        sortable: 'true',
        value: 'RefundAmount',
        category: ['Refunds'],
        visible: true,
      },
      {
        text: 'Refund Received',
        align: 'center',
        sortable: 'true',
        value: 'RefundReceived',
        category: ['Refunds'],
        visible: true,
      },
      {
        text: 'Refund Processed',
        align: 'center',
        sortable: 'true',
        value: 'RefundProcessed',
        category: ['Refunds'],
        visible: true,
      },
      {
        text: 'Review Type',
        align: 'center',
        sortable: 'true',
        value: 'ReviewType',
        category: ['Reviews'],
        visible: true,
      },
      {
        text: 'Review Submitted',
        align: 'center',
        sortable: 'true',
        value: 'ReviewSubmitted',
        category: ['Reviews'],
        visible: true,
      },
      {
        text: 'Review Completed',
        align: 'center',
        sortable: 'true',
        value: 'ReviewCompleted',
        category: ['Reviews'],
        visible: true,
      },
      {
        text: 'Reviewer',
        align: 'center',
        sortable: 'true',
        value: 'Reviewer',
        category: ['Reviews'],
        visible: true,
      },
      {
        text: 'Expense POV',
        align: 'center',
        sortable: 'true',
        value: 'ExpensePOV',
        category: ['Expenses'],
        visible: true,
      },
      {
        text: 'Expense State Car',
        align: 'center',
        sortable: 'true',
        value: 'ExpenseStateCar',
        category: ['Expenses'],
        visible: true,
      },
      {
        text: 'Expense AirFare',
        align: 'center',
        sortable: 'true',
        value: 'ExpenseAirFare',
        category: ['Expenses'],
        visible: true,
      },
      {
        text: 'Expense Hotel',
        align: 'center',
        sortable: 'true',
        value: 'ExpenseHotel',
        category: ['Expenses'],
        visible: true,
      },
      {
        text: 'Expense MIE',
        align: 'center',
        sortable: 'true',
        value: 'ExpenseMIE',
        category: ['Expenses'],
        visible: true,
      },
      {
        text: 'Expense Rental Car',
        align: 'center',
        sortable: 'true',
        value: 'ExpenseRentalCar',
        category: ['Expenses'],
        visible: true,
      },
      {
        text: 'Expense Other',
        align: 'center',
        sortable: 'true',
        value: 'ExpenseOther',
        category: ['Expenses'],
        visible: true,
      },
      {
        text: 'Expense Total',
        align: 'center',
        sortable: 'true',
        value: 'ExpenseTotal',
        category: ['Expenses'],
        visible: true,
      },
      {
        text: 'Expense Comments',
        align: 'center',
        sortable: 'true',
        value: 'ExpenseComments',
        category: ['Expenses'],
        visible: false,
      },
      {
        text: 'Expense Auditor',
        align: 'center',
        sortable: 'true',
        value: 'ExpenseAuditor',
        category: ['Expenses'],
        visible: true,
      },
      {
        text: 'Budget Hours Activity Code',
        align: 'center',
        sortable: 'true',
        value: 'BudgetHoursActivityCode',
        category: ['BudgetHours'],
        visible: true,
      },
      {
        text: 'Budget Hours Activity Code Description',
        align: 'center',
        sortable: 'true',
        value: 'BudgetHoursActivityCodeDescription',
        category: ['BudgetHours'],
        visible: true,
      },
      {
        text: 'Standard Budget Hours',
        align: 'center',
        sortable: 'true',
        value: 'StandardBudgetHours',
        category: ['BudgetHours'],
        visible: true,
      },
      {
        text: 'Budgeted Hours',
        align: 'center',
        sortable: 'true',
        value: 'BudgetedHours',
        category: ['BudgetHours'],
        visible: true,
      },
      {
        text: 'Budgeted Hours Comments',
        align: 'center',
        sortable: 'true',
        value: 'BudgetedHoursComments',
        category: ['BudgetHours'],
        visible: false,
      },
      {
        text: 'Budgeted Hours Approved',
        align: 'center',
        sortable: 'true',
        value: 'BudgetedHoursApproved',
        category: ['BudgetHours'],
        visible: true,
      },
      {
        text: 'Budgeted Hours Approved By',
        align: 'center',
        sortable: 'true',
        value: 'BudgetedHoursApprovedBy',
        category: ['BudgetHours'],
        visible: true,
      },
      {
        text: 'Budgeted Hours Approval Date',
        align: 'center',
        sortable: 'true',
        value: 'BudgetedHoursApprovalDate',
        category: ['BudgetHours'],
        visible: true,
      },
    ],
    headerCategories: [
      {text: 'Dates', value: 'Dates', divisions: [3, 4, 5, 6]},
      {text: 'Findings', value: 'Findings', divisions: [5, 6]},
      {text: 'Collections', value: 'Collections', divisions: [5, 6]},
      {text: 'Reported Values', value: 'ReportedValues', divisions: [5, 6]},
      {text: 'Refunds', value: 'Refunds', divisions: [3]},
      {text: 'Reviews', value: 'Reviews', divisions: [3]},
      {text: 'Expenses', value: 'Expenses', divisions: [3]},
      {text: 'Budgeted Hours', value: 'BudgetHours', divisions: [4]},
    ],
  }),
  created() {
    this.selectedFields = this.availableHeaders
      .filter((h) => h.visible)
      .map((h) => h.value)
    this.loadLuDepartmentStrategy() //
    this.loadDivision() //
    this.loadLuDateType() //
    this.loadLuCounty()
    this.loadLuFindingType() //
    this.loadLuPFEntityType() //
    this.loadLuLicenseType() //
    this.loadLuReportedType() //
    this.loadLuReviewType() //
    this.loadActivityCodes()
    this.loadProjectDetail() //
    this.searchReports()
    this.getQueriesSaved()
    this.bounce(this)
  },
  watch: {
    pagination: {
      handler() {
        this.bounce(this)
      },
      deep: true,
    },
    //reset pivot fields based on selected fields
    selectedFields: {
      handler(nval, oval) {
        this.headers.forEach((item) => {
          if (nval.includes(item.value)) {
            item.visible = true
          } else {
            item.visible = false
          }
        })
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
      deep: true,
    },
    //make sure we don't have selectedFields that are not available any longer
    availableHeaders: {
      handler(nval, oval) {
        let selectedFields = this.selectedFields.filter((item) => {
          return nval.find((v) => v.value == item)
        })
        this.selectedFields = selectedFields
      },
      deep: true,
    },
    //we need to remove selections from every parameter list if they are no longer available
    availableCategories: {
      handler(nval, oval) {
        let selectedFields = this.selectedCategories.filter((item) => {
          return nval.find((v) => v.value == item)
        })
        this.selectedCategories = selectedFields
      },
    },
    availableProjectDetails: {
      handler(nval, oval) {
        let selectedFields = this.projectDetails.filter((item) => {
          return nval.find((v) => v.ID == item)
        })
        this.projectDetails = selectedFields
      },
    },
    availableProjectDetailsPrefix: {
      handler(nval, oval) {
        let selectedFields = this.projectDetailsPrefix.filter((item) => {
          return nval.find((v) => v == item)
        })
        this.projectDetailsPrefix = selectedFields
      },
    },
    availableDepartmentStrategies: {
      handler(nval, oval) {
        let selectedFields = this.departmentStrategy.filter((item) => {
          return nval.find((v) => v.Code == item)
        })
        this.departmentStrategy = selectedFields
      },
    },
    availableLicenseTypes: {
      handler(nval, oval) {
        let selectedFields = this.licenseTypes.filter((item) => {
          return nval.find((v) => v.ID == item)
        })
        this.licenseTypes = selectedFields
      },
    },
    availableEntityTypes: {
      handler(nval, oval) {
        let selectedFields = this.entityTypes.filter((item) => {
          return nval.find((v) => v.ID == item)
        })
        this.entityTypes = selectedFields
      },
    },
    availableDateTypes: {
      handler(nval, oval) {
        let selectedFields = this.dateTypes.filter((item) => {
          return nval.find((v) => v.ID == item)
        })
        this.dateTypes = selectedFields
      },
    },
    availableFindingTypes: {
      handler(nval, oval) {
        let selectedFields = this.findingTypes.filter((item) => {
          return nval.find((v) => v.ID == item)
        })
        this.findingTypes = selectedFields
        let selectedColFields = this.collectionTypes.filter((item) => {
          return nval.find((v) => v.ID == item)
        })
        this.collectionTypes = selectedColFields
      },
    },
    availableReportedTypes: {
      handler(nval, oval) {
        let selectedFields = this.reportedValueTypes.filter((item) => {
          return nval.find((v) => v.ID == item)
        })
        this.reportedValueTypes = selectedFields
      },
    },
    availableReviewTypes: {
      handler(nval, oval) {
        let selectedFields = this.reviewTypes.filter((item) => {
          return nval.find((v) => v.ID == item)
        })
        this.reviewTypes = selectedFields
      },
    },
    availableActivityCodes: {
      handler(nval, oval) {
        let selectedFields = this.activityCode.filter((item) => {
          return nval.find((v) => v.ID == item)
        })
        this.activityCode = selectedFields
      },
    },

    //for each search parameter we need to call bounce to search once the user has made changes
    searchText: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    startDate: {
      handler() {
        this.bounce(this)
      },
    },
    endDate: {
      handler() {
        this.bounce(this)
      },
    },
    selectedCategories: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    projectDivision: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    project: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    entity: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    projectDetails: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    projectDetailsPrefix: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    departmentStrategy: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    projectStatus: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    entityStatus: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    subEntityStatus: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    leads: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    users: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    counties: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    licenseTypes: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    entityTypes: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    dateTypes: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    findingTypes: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    collectionTypes: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    reportedValueTypes: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    reviewTypes: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    activityCode: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },

    //autocomplete search watches
    projectSearchText: {
      handler() {
        this.projectSearchBounce(this)
      },
    },
    entitySearchText: {
      handler() {
        this.entitySearchBounce(this)
      },
    },
    userSearchText: {
      handler() {
        this.userSearchBounce(this)
      },
    },
    user2SearchText: {
      handler() {
        this.user2SearchBounce(this)
      },
    },
    currentUser: {
      handler(nval) {
        if (nval) {
          this.getQueriesSaved()
        }
      },
    },
  },
  computed: {
    ...authComputed,
    luDepartmentStrategy: get('luDepartmentStrategy/luDepartmentStrategy'),
    division: get('division/division'),
    projectDetail: get('projectDetail/projectDetail'),
    luPFEntityType: get('luPfEntityType/luPFEntityType'),
    luLicenseType: get('luLicenseType/luLicenseType'),
    luDateType: get('luDateType/luDateType'),
    luFindingType: get('luFindingType/luFindingType'),
    luReportedType: get('luReportedType/luReportedType'),
    luReviewType: get('luReviewType/luReviewType'),
    luCounty: get('luCounty/luCounty'),
    activityCodes: get('activityCodes/activityCodes'),
    loading() {
      return this.searchCount > 0
    },
    projectSearchIsLoading() {
      return this.projectSearchCount > 0
    },
    entitySearchIsLoading() {
      return this.entitySearchCount > 0
    },
    userSearchIsLoading() {
      return this.userSearchCount > 0
    },
    user2SearchIsLoading() {
      return this.user2SearchCount > 0
    },
    availableCategories() {
      let list = this.headerCategories.filter((item) => {
        return (
          this.isSuperUser ||
          item.divisions.includes(this.currentUser.DivisionID)
        )
      })
      if (
        this.isSuperUser &&
        this.projectDivision &&
        this.projectDivision.length > 0
      ) {
        list = list.filter((item) => {
          let intersect = item.divisions.filter((element) =>
            this.projectDivision.includes(element)
          )
          return intersect.length > 0
        })
      }
      return list
    },
    availableHeaders() {
      let list = this.headers.filter((item) => {
        if (item.divisions && item.divisions.length > 0) {
          return (
            this.isSuperUser ||
            item.divisions.includes(this.currentUser.DivisionID)
          )
        } else {
          return true
        }
      })
      if (
        this.isSuperUser &&
        this.projectDivision &&
        this.projectDivision.length > 0
      ) {
        list = list.filter((item) => {
          if (item.divisions && item.divisions.length > 0) {
            let intersect = item.divisions.filter((element) =>
              this.projectDivision.includes(element)
            )
            return intersect.length > 0
          } else {
            return true
          }
        })
      }
      list = list.filter((item) => {
        if (item.category && item.category.length > 0) {
          // if (this.selectedCategories.length > 0) {
          let intersect = this.selectedCategories.filter((element) =>
            item.category.includes(element)
          )
          return intersect.length > 0
          // } else if (
          //   this.availableCategories &&
          //   this.availableCategories.length > 0
          // ) {
          //   let intersect = this.availableCategories.filter((element) => {
          //     return item.category.includes(element.value)
          //   })
          //   return intersect.length > 0
          // } else {
          //   return true
          // }
        } else {
          return true
        }
      })
      return list
    },
    filteredHeaders: {
      get() {
        let list = this.availableHeaders.filter((h) => h.visible)
        list.sort((a, b) => {
          return (
            this.selectedFields.indexOf(a.value) -
            this.selectedFields.indexOf(b.value)
          )
        })
        return list
      },
    },
    selectedQuery() {
      return this.availableSavedQueries.find(
        (q) => q.ID == this.selectedQueryID
      )
    },
    selectedQueryParams() {
      if (this.selectedQuery) {
        return JSON.parse(this.selectedQuery.Query)
      }
    },
    searchParams() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        sortProperty: this.pagination.sortBy,
        sortDirection: this.pagination.descending ? 'desc' : 'asc',
        searchText: this.searchText,
        selectedCategories: this.selectedCategories,
        includeFields: this.selectedFields,
        projectDivision: this.isSuperUser
          ? this.projectDivision
          : [this.currentUser.DivisionID],
        project: this.project,
        entity: this.entity,
        projectDetails: this.projectDetails,
        projectDetailsPrefix: this.projectDetailsPrefix,
        departmentStrategy: this.departmentStrategy,
        projectStatus: this.projectStatus,
        entityStatus: this.entityStatus,
        subEntityStatus: this.subEntityStatus,
        leads: this.leads,
        users: this.users,
        counties: this.counties,
        licenseTypes: this.licenseTypes,
        entityTypes: this.entityTypes,
        dateTypes: this.dateTypes,
        findingTypes: this.findingTypes,
        //use findingtypes list?
        collectionTypes: this.collectionTypes,
        reportedValueTypes: this.reportedValueTypes,
        reviewTypes: this.reviewTypes,
        activityCode: this.activityCode,
        queryName: this.queryName,
        queryIsModified: this.queryIsModified,
        includePivot: this.pivot.includePivot,
        pivotFirst: this.pivot.pivotFirst,
        pivotName: this.pivot.pivotName,
        rowFields: this.pivot.rowFields,
        columnFields: this.pivot.columnFields,
        dataFields: this.pivot.dataFields,
        excludeFields: this.pivot.excludeFields,
        filterFields: this.pivot.filterFields,
      }
    },
    projectSearchParams() {
      if (
        !!this.projectSearchText ||
        this.projectDivision.length > 0 ||
        this.entity.length > 0 ||
        this.projectDetails.length > 0 ||
        this.projectDetailsPrefix.length > 0 ||
        this.departmentStrategy.length > 0
      ) {
        return {
          searchText: this.projectSearchText,
          projectStatus: this.projectStatus,
          division: this.isSuperUser
            ? this.projectDivision
            : [this.currentUser.DivisionID],
          entity: this.entity,
          projectDetails: this.projectDetails,
          projectDetailsPrefix: this.projectDetailsPrefix,
          departmentStrategy: this.departmentStrategy,
        }
      } else {
        return null
      }
    },
    entitySearchParams() {
      if (!!this.entitySearchText || this.projectDivision.length > 0) {
        return {
          searchText: this.entitySearchText,
          entityStatus: this.entityStatus,
          division: this.isSuperUser
            ? this.projectDivision
            : [this.currentUser.DivisionID],
        }
      } else {
        return null
      }
    },
    userSearchParams() {
      if (!!this.userSearchText || this.projectDivision.length > 0) {
        return {
          searchText: this.userSearchText,
          division: this.isSuperUser
            ? this.projectDivision
            : [this.currentUser.DivisionID],
        }
      } else {
        return null
      }
    },
    user2SearchParams() {
      if (!!this.user2SearchText || this.projectDivision.length > 0) {
        return {
          searchText: this.user2SearchText,
          division: this.isSuperUser
            ? this.projectDivision
            : [this.currentUser.DivisionID],
        }
      } else {
        return null
      }
    },
    availableProjectDivisions() {
      let list = this.division.filter((item) => {
        return this.isSuperUser || item.ID == this.currentUser.DivisionID
      })
      return list
    },
    availableDepartmentStrategies() {
      let list = this.luDepartmentStrategy.filter((item) => {
        return (
          this.isSuperUser || item.DivisionID == this.currentUser.DivisionID
        )
      })
      if (this.projectDivision && this.projectDivision.length > 0) {
        list = list.filter((item) =>
          this.projectDivision.includes(item.DivisionID)
        )
      }
      list.sort((a, b) => {
        return a.Code > b.Code ? 1 : -1
      })
      return list
    },
    availableProjectDetails() {
      let list = this.projectDetail.filter(
        (item) =>
          this.isSuperUser || item.DivisionID == this.currentUser.DivisionID
      )
      if (this.projectDivision && this.projectDivision.length > 0) {
        list = list.filter((item) =>
          this.projectDivision.includes(item.DivisionID)
        )
      }
      if (this.projectDetailsPrefix && this.projectDetailsPrefix.length > 0) {
        list = list.filter((item) =>
          this.projectDetailsPrefix.includes(item.Prefix)
        )
      }
      list.sort((a, b) => {
        return a.Prefix != b.Prefix
          ? a.Prefix > b.Prefix
            ? 1
            : -1
          : a.Description > b.Description
          ? 1
          : -1
      })
      return list
    },
    availableProjectDetailsPrefix() {
      let list = this.projectDetail.filter((item) => {
        return (
          this.isSuperUser || item.DivisionID == this.currentUser.DivisionID
        )
      })
      if (this.projectDivision && this.projectDivision.length > 0) {
        list = list.filter((item) =>
          this.projectDivision.includes(item.DivisionID)
        )
      }
      let distinctList = [...new Set(list.map((item) => item.Prefix))]
      distinctList.sort()
      return distinctList
    },
    availableDateTypes() {
      let list = this.luDateType.filter((item) => {
        return (
          this.isSuperUser || item.DivisionID == this.currentUser.DivisionID
        )
      })
      if (this.projectDivision && this.projectDivision.length > 0) {
        list = list.filter((item) =>
          this.projectDivision.includes(item.DivisionID)
        )
      }
      list.sort((a, b) => {
        let textField = 'DateType'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return list
    },
    availableFindingTypes() {
      let list = this.luFindingType.filter((item) => {
        return (
          this.isSuperUser || item.DivisionID == this.currentUser.DivisionID
        )
      })
      if (this.projectDivision && this.projectDivision.length > 0) {
        list = list.filter((item) =>
          this.projectDivision.includes(item.DivisionID)
        )
      }
      list.sort((a, b) => {
        let textField = 'FindingType'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return list
    },
    availableReportedTypes() {
      let list = this.luReportedType.filter((item) => {
        return (
          this.isSuperUser || item.DivisionID == this.currentUser.DivisionID
        )
      })
      if (this.projectDivision && this.projectDivision.length > 0) {
        list = list.filter((item) =>
          this.projectDivision.includes(item.DivisionID)
        )
      }
      list.sort((a, b) => {
        let textField = 'ReportedType'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return list
    },
    availableReviewTypes() {
      let list = this.luReviewType.filter((item) => {
        return true
      })
      list.sort((a, b) => {
        let textField = 'Description'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return list
    },
    availableEntityTypes() {
      let list = this.luPFEntityType.filter((item) => {
        return true
      })
      list.sort((a, b) => {
        let textField = 'Description'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return list
    },
    availableLicenseTypes() {
      let list = this.luLicenseType.filter((item) => {
        return (
          this.isSuperUser || item.DivisionID == this.currentUser.DivisionID
        )
      })
      if (this.projectDivision && this.projectDivision.length > 0) {
        list = list.filter((item) =>
          this.projectDivision.includes(item.DivisionID)
        )
      }
      list.sort((a, b) => {
        let textField = 'Description'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return list
    },
    availableActivityCodes() {
      let list = this.activityCodes.filter((item) => {
        return (
          this.isSuperUser || item.DivisionID == this.currentUser.DivisionID
        )
      })
      if (this.projectDivision && this.projectDivision.length > 0) {
        list = list.filter((item) =>
          this.projectDivision.includes(item.DivisionID)
        )
      }
      list.sort((a, b) => {
        let textField = 'Description'
        let sort = a[textField] > b[textField] ? 1 : -1
        return sort
      })
      return list.map((item) => {
        return {
          ...item,
          display: item.Code + ' ' + item.Description,
        }
      })
    },
    availableSavedQueries() {
      let list = this.savedQueries
      let list2 = []
      if (this.staticReports.length > 0) {
        this.staticReports.forEach((r) => {
          if (
            r.filterDivision.length == 0 ||
            r.filterDivision.includes(this.currentUser.DivisionID)
          ) {
            let query = r.Query
            if (r.currentUserProjectDivision) {
              query.projectDivision = [this.currentUser.DivisionID]
            }
            list2.push({Title: r.Title, ID: r.ID, Query: JSON.stringify(query)})
          }
        })
        list2.sort((a, b) => b.ID - a.ID)
      }
      return list2.concat(list)
    },
    divisionDisplayList() {
      if (this.isSuperUser) {
        if (this.projectDivision && this.projectDivision.length > 0) {
          return this.projectDivision
        } else {
          return [2, 3, 4, 5, 6]
        }
      } else {
        return [this.currentUser.DivisionID]
      }
    },
  },
  methods: {
    loadLuDepartmentStrategy: call(
      'luDepartmentStrategy/loadLuDepartmentStrategy'
    ),
    loadDivision: call('division/loadDivision'),
    loadLuCounty: call('luCounty/loadLuCounty'),
    loadLuDateType: call('luDateType/loadLuDateType'),
    loadLuFindingType: call('luFindingType/loadLuFindingType'),
    loadLuPFEntityType: call('luPfEntityType/loadLuPFEntityType'),
    loadLuLicenseType: call('luLicenseType/loadLuLicenseType'),
    loadLuReportedType: call('luReportedType/loadLuReportedType'),
    loadLuReviewType: call('luReviewType/loadLuReviewType'),
    loadActivityCodes: call('activityCodes/loadActivityCodes'),
    loadProjectDetail: call('projectDetail/loadProjectDetail'),
    createSavedQueriesProject: call(
      'savedQueriesProject/createSavedQueriesProject'
    ),
    deleteSavedQueriesProject: call(
      'savedQueriesProject/deleteSavedQueriesProject'
    ),
    handleError: call('errors/handleError'),
    toggleFilters() {
      this.filters = !this.filters
      if (this.filters) {
        this.filterText = '-Hide Filters-'
      } else {
        this.filterText = '-Show Filters-'
      }
    },
    getQueriesSaved() {
      if (this.currentUser) {
        const url = `SavedQueriesProject/User/${this.currentUser.ID}`
        this.$axios.get(url).then(
          (res) => {
            this.savedQueries = res.data
          },
          (error) => {
            this.handleError(error)
            // this.error = error
            // this.displayError = true
            console.error(error)
          }
        )
      }
    },
    deleteConfirm(item) {
      this.deleteQuery = item
      this.deleteDialog = true
    },
    deleteQueries() {
      let index = this.savedQueries.findIndex(
        (q) => q.ID == this.deleteQuery.ID
      )
      this.deleteSavedQueriesProject(this.deleteQuery).then(
        (res) => {
          this.savedQueries.splice(index, 1)
        },
        (err) => {}
      )
      this.deleteDialog = false
    },
    searchReports() {
      if (!this.pagination.sortBy) {
        this.pagination.sortBy = 'ProjectNumber'
        this.pagination.descending = false
      }
      if (Array.isArray(this.pagination.sortBy)) {
        if (this.pagination.sortBy.length > 0) {
          this.pagination.sortBy = this.pagination.sortBy[0]
        } else {
          this.pagination.sortBy = 'ProjectNumber'
        }
        this.pagination.descending = false
      }
      if (!this.searchText) {
        this.searchText = ''
      }
      this.searchCount++
      const url = `AdHocProject/Display?pageNum=${
        this.pagination.page - 1
      }&pageSize=${this.pagination.rowsPerPage}`
      this.$axios.post(url, this.searchParams).then(
        (res) => {
          this.pagination.rowsPerPage = res.data.Page.Size
          this.pagination.page = res.data.Page.Number + 1
          this.total = res.data.Page.TotalElements
          this.entries = res.data.Entries
          this.searchCount--
        },
        (error) => {
          this.handleError(error)
          // this.error = error
          // this.displayError = true
          console.error(error)
          this.searchCount--
        }
      )
    },
    searchUsers() {
      if (!!this.userSearchParams) {
        const url = `User/Lookup?search=${JSON.stringify(
          this.userSearchParams
        )}`
        this.userSearchCount++
        this.$axios.get(url).then(
          (res) => {
            this.userList = res.data.map((item) => {
              return {
                ...item,
                fullName: item.FirstName + ' ' + item.LastName,
              }
            })
            this.userSearchCount--
          },
          (error) => {
            this.handleError(error)
            console.error(error)
            this.userSearchCount--
          }
        )
      } else this.userList = []
    },
    searchUsers2() {
      if (!!this.user2SearchParams) {
        const url = `User/Lookup?search=${JSON.stringify(
          this.user2SearchParams
        )}`
        this.user2SearchCount++
        this.$axios.get(url).then(
          (res) => {
            this.userList2 = res.data.map((item) => {
              return {
                ...item,
                fullName: item.FirstName + ' ' + item.LastName,
              }
            })
            this.user2SearchCount--
          },
          (error) => {
            this.handleError(error)
            console.error(error)
            this.user2SearchCount--
          }
        )
      } else this.userList2 = []
    },
    searchEntities() {
      if (!!this.entitySearchParams) {
        const url = `AuditEntity/Lookup?search=${JSON.stringify(
          this.entitySearchParams
        )}`
        this.entitySearchCount++
        this.$axios.get(url).then(
          (res) => {
            this.entityList = res.data.map((item) => {
              return {
                ...item,
              }
            })
            this.entitySearchCount--
          },
          (error) => {
            this.handleError(error)
            console.error(error)
            this.entitySearchCount--
          }
        )
      } else this.entityList = []
    },
    searchProjects() {
      if (!!this.projectSearchParams) {
        const url = `Project/Lookup?search=${JSON.stringify(
          this.projectSearchParams
        )}`
        this.projectSearchCount++
        this.$axios.get(url).then(
          (res) => {
            this.projectList = res.data.map((item) => {
              return {
                ...item,
                details: item.ProjectNumber + ' ' + item.Description,
              }
            })
            this.projectSearchCount--
          },
          (error) => {
            this.handleError(error)
            console.error(error)
            this.projectSearchCount--
          }
        )
      } else this.projectList = []
    },
    getAdhoc(type) {
      let headers = {responseType: 'blob'}
      let url = 'AdHocProject'
      if (type == 'pdf') {
        url += '/pdf'
      }
      this.$axios.post(url, this.searchParams, headers).then(
        (res) => {
          const content = res.headers['content-type']
          const dispo = res.headers['content-disposition']
          var filename = ''
          if (dispo && dispo.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(dispo)
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
            }
          }
          download(res.data, filename, content)
        },
        (error) => {
          this.handleError(error)
          // this.error = error
          // this.displayError = true
          console.error(error)
        }
      )
    },

    bounce: _.debounce((self) => {
      self.searchReports()
    }, 2000),
    projectSearchBounce: _.debounce((self) => {
      self.searchProjects()
    }, 500),
    entitySearchBounce: _.debounce((self) => {
      self.searchEntities()
    }, 500),
    userSearchBounce: _.debounce((self) => {
      self.searchUsers()
    }, 500),
    user2SearchBounce: _.debounce((self) => {
      self.searchUsers2()
    }, 500),
    showColumn(name) {
      return this.headers.find((h) => h.value === name).visible
    },
    selectQuery() {
      if (this.selectedQuery && this.selectedQuery.Query) {
        let query = JSON.parse(this.selectedQuery.Query)
        this.projectDivision = this.isSuperUser
          ? query.projectDivision
          : [this.currentUser.DivisionID]
        this.pagination.descending =
          query.sortDirection == 'desc' ? true : false
        this.pagination.sortBy = query.sortProperty || 'ProjectNumber'
        this.searchText = query.searchText || ''
        this.selectedCategories = query.selectedCategories || []
        this.selectedFields = query.includeFields || []
        this.projectStatus = query.projectStatus || []
        this.entityStatus = query.entityStatus || []
        this.subEntityStatus = query.subEntityStatus || []
        this.leads = query.leads || []
        this.users = query.users || []
        this.counties = query.counties || []
        this.licenseTypes = query.licenseTypes || []
        this.entityTypes = query.entityTypes || []
        this.dateTypes = query.dateTypes || []
        this.findingTypes = query.findingTypes || []
        this.collectionTypes = query.collectionTypes || []
        this.reportedValueTypes = query.reportedValueTypes || []
        this.reviewTypes = query.reviewTypes || []
        this.activityCode = query.activityCode || []
        this.project = query.project || []
        this.entity = query.entity || []
        this.projectDetails = query.projectDetails || []
        this.projectDetailsPrefix = query.projectDetailsPrefix || []
        this.departmentStrategy = query.departmentStrategy || []
        this.queryName = query.queryName || this.selectedQuery.Title
        this.pivot.includePivot = query.includePivot
        this.pivot.pivotFirst = query.pivotFirst
        this.pivot.pivotName = query.pivotName
        this.pivot.rowFields = query.rowFields || []
        this.pivot.columnFields = query.columnFields || []
        this.pivot.excludeFields = query.excludeFields || []
        this.pivot.dataFields = query.dataFields || []
        this.pivot.filterFields = query.filterFields || []
      } else {
        this.resetQuery()
      }
      if (this.selectedQuery && this.selectedQuery.ID <= 0) {
        //this can be used for additional processing for query presets that are hardcoded in javascript,
        let staticQuery = this.staticReports.filter((item) => {
          return item.ID == this.selectedQuery.ID
        })
        if (staticQuery && staticQuery.length > 0) {
          if (staticQuery[0].clearDateFields) {
            ;(this.startDate = null), (this.endDate = null)
          } else if (!this.startDate || !this.endDate) {
            this.startDate = dayjs().subtract(1, 'year').format('YYYY-MM-DD')
            this.endDate = dayjs().format('YYYY-MM-DD')
          }
        }
      }
      this.$nextTick(() => {
        this.queryIsModified = false
      })
    },
    resetQuery() {
      this.queryName = ''
      this.queryIsModified = false
    },
    saveQuery() {
      var savedQueries = new SavedQueriesProject()
      savedQueries.UserID = this.currentUser.ID
      savedQueries.Title = this.savedQueriesTitle
      this.queryIsModified = false
      this.queryName = this.savedQueriesTitle
      savedQueries.Query = JSON.stringify(this.searchParams)
      this.createSavedQueriesProject(savedQueries).then((res) => {
        this.savedQueries.push(res.data)
      })
      //this.user = new User()
      this.modalText = 'Save Query'
      this.dialog = !this.dialog
    },
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>
