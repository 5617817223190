export default class SavedQueriesProject {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //UserId is required
      UserId: 0,
      //Title is required
      Title: '',
      //Query is required
      Query: '',
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.UserId = obj.UserId
    this.Title = obj.Title
    this.Query = obj.Query
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    return obj
  }
}
